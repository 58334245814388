
import { Component, Vue } from "vue-property-decorator";
import { ApiCustomersAdminIdGetRequest, SendUpdateOtpDto, CustomersViewModel, CustomersUpdateEmailDto, CustomersUpdateMobileNumberDto, MessageType } from "@/api-client";
import { handleInvalidForm } from "@/utils/auth";
import { Rule, ValidateFieldsError } from "@/types/async-validator";
import { PropType } from "vue/types/v3-component-props";
import { customersAdminIdPut, sendCustomerOtp, updateCustomerContact } from "@/api/customers";
import DialogBox from "@/components/DialogBox.vue";

// This is for typing the form rules it supports nested items like the adresses object properties
type DotPrefix<T extends string> = T extends "" ? "" : `.${T}`;
type DotNestedKeys<T> = (T extends object ? { [K in Exclude<keyof T, symbol>]: `${K}${DotPrefix<DotNestedKeys<T[K]>>}` }[Exclude<keyof T, symbol>] : "") extends infer D ? Extract<D, string> : never;
type FormApiCustomersAdminIdGetRequest = DotNestedKeys<ApiCustomersAdminIdGetRequest>;
type ContactFormDetails<T> = { [T in MessageType]: { title: string; description: string } };

@Component({
  name: "CustomerEditForm",
  components: { DialogBox },
  props: {
    data: { required: true, type: Object as PropType<CustomersViewModel> },
    isEditing: { required: true, type: Boolean, default: false },
  },
})
export default class extends Vue {
  dialogVisible = false;
  genderDdl: ("Female" | "Male")[] = ["Female", "Male"];
  formGeneralData: ApiCustomersAdminIdGetRequest = {
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    additionalNumber: "",
    dateOfBirth: "",
    gender: "",
    address: {
      line1: "",
      line2: "",
      line3: "",
      suburb: "",
      city: "",
      province: "",
      country: "",
      postalCode: "",
    },
  };
  updateContactType: MessageType = MessageType.Email;
  generalFormSubmit = false
  otpSent = false;
  formEmailData: { emailAddress: string } = {
    emailAddress: "",
  };
  formMobileData: { mobileNumber: string } = {
    mobileNumber: "",
  };
  formOtpData: { otp: string } = {
    otp: "",
  };
  contactFormDetails: ContactFormDetails<MessageType> = {
    Email: {
      title: "Update Customer Email Address",
      description: `Enter the customer's updated email address below. The customer will receive a one time password (OTP) so will need immediate access to the supplied email address:`,
    },
    SMS: {
      title: "Update Customer Cellphone Number",
      description: `Enter the customer's updated cellphone number below. The customer will receive a one time password (OTP) so will need immediate access to the supplied cellphone number:`,
    },
  };

  get generalRules(): Partial<Record<FormApiCustomersAdminIdGetRequest, Rule>> {
    return {
      firstName: [{ required: true, message: "Please enter customer's first name" }],
      lastName: [{ required: true, message: "Please enter customer's last name" }],
      additionalNumber: [{ pattern: /^0\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s?$/, message: "Please enter a valid cellphone number (no country code)" }],
      "address.line1": [{ required: true, message: "Please enter customer's line 1" }],
      "address.suburb": [{ required: true, message: "Please enter customer's suburb" }],
      "address.city": [{ required: true, message: "Please enter customer's city" }],
      "address.country": [{ required: true, message: "Please enter customer's country" }],
      "address.postalCode": [{ required: true, message: "Please enter customer's postal code" }],
    };
  }

  get otpRules(): Record<"otp", Rule> {
    return {
      otp: [{ required: true, message: "Please enter the OTP" }],
    };
  }

  get emailRules(): Record<"emailAddress", Rule> {
    return {
      emailAddress: [{ required: true, type: "email", message: "Please enter a valid updated email" }],
    };
  }

  get mobileRules(): Record<"mobileNumber", Rule> {
    return {
      mobileNumber: [
        { required: true, message: "Please enter customer's updated cellphone number" },
        { pattern: /^0\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s?$/, message: "Please enter a valid cellphone number (no country code)" },
      ],
    };
  }

  created() {
    this.setForm()
  }

  setForm() {
    this.formGeneralData = { ...this.$props.data }
  }

  sendOTPGeneralUpdate(type: MessageType) {
    this.updateContactType = type;
    this.callContactForm("formGeneralData")
  }

  callContactForm(formName: string, generalForm = false) {
    // Needed to do this as there were scoping issues in submitContactForm function that couldn't be solved - variables kept resetting to the original value.
    // This seemed like the best alternative by pushing 'this' through from this function
    this.submitContactForm(formName, generalForm, this)
  }

  submitForm(formName: string) {
    (this.$refs[formName] as any)?.validate((valid: boolean, fields: ValidateFieldsError) => {
      if (valid) {
        customersAdminIdPut(this.formGeneralData.id, this.formGeneralData).then(async () => {
          this.$emit("reload");
          this.$emit("close")
          this.closeUpdateContactDialog()
        });
        console.log("valid");
        // handle valid
      } else {
        handleInvalidForm(fields as ValidateFieldsError);
      }
    });
  }

  submitContactForm = (formName: string, generalForm = false, self = this) => {
    (self.$refs[formName] as any)?.validate(async (valid: boolean, fields: ValidateFieldsError) => {
      if (valid) {

        if (self.otpSent) {
          // Validate OTP
          let payload;
          switch (self.updateContactType) {
            case "Email": {
              payload = {
                id: self.$props.data.id,
                emailAddress: !generalForm ? self.formEmailData.emailAddress : self.$props.data.emailAddress,
                otp: self.formOtpData.otp,
              } as CustomersUpdateEmailDto;
              break;
            }

            case "SMS": {
              payload = {
                id: self.$props.data.id,
                mobileNumber: !generalForm ? self.formMobileData.mobileNumber : self.$props.data.mobileNumber,
                otp: self.formOtpData.otp,
              } as CustomersUpdateMobileNumberDto;
              break;
            }

            default:
              break;
          }

          if (payload) {
            await updateCustomerContact(self.updateContactType, payload, generalForm)
              .then(async () => {
                if(generalForm) {
                  self.submitForm('formGeneralData')
                } else {
                  self.$emit("reload");
                  self.closeUpdateContactDialog()
                }
              })
              .catch(() => self.closeUpdateContactDialog());
          }
        } else {
          // Send OTP
          if (self.updateContactType === "SMS") {
            self.formMobileData.mobileNumber = self.formMobileData.mobileNumber.replaceAll(" ", "");
          }

          await self.sendOTP();
        }
      } else {
        handleInvalidForm(fields as ValidateFieldsError);
      }
    });
  };

  async sendOTP() {
    let contact;

    if(this.generalFormSubmit) {
      contact = this.updateContactType === "Email" ? this.$props.data.emailAddress : this.$props.data.mobileNumber
    } else {
      contact = this.updateContactType === "Email" ? this.formEmailData.emailAddress : this.formMobileData.mobileNumber
    }

    const payload: SendUpdateOtpDto = {
      customersId: this.$props.data.id,
      messageType: this.updateContactType,
      contact: contact
    };

    await sendCustomerOtp(payload)
      .then((res) => {
        this.otpSent = true;
      })
      .catch(() => {
        this.closeUpdateContactDialog()
      });
  }

  updateViaOtp(type: MessageType) {
    this.updateContactType = type;
    this.dialogVisible = true;
  }

  closeUpdateContactDialog() {
    this.formEmailData = {
      emailAddress: "",
    };
    this.formMobileData = {
      mobileNumber: "",
    };
    this.formOtpData = {
      otp: "",
    };
    this.generalFormSubmit = false;
    this.otpSent = false;
    this.dialogVisible = false;
  }
}
