import { OrderMessages } from "@/network/api";
import { handleError } from "@/utils/auth";
import { Message } from "element-ui";

export function ordersNotificationsMessagesIdResendPut(messagesId: string): Promise<void> {
  return new Promise((resolve, reject) => {
    OrderMessages.apiOrdersNotificationsMessagesIdResendPut(messagesId)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        Message.success({ message: "Notification resent!", showClose: true })
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}