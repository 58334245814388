
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import Pagination from "@/components/Pagination.vue";
import { AuditTypes, PagedResultOfAuditLogDto } from "@/api-client";
import { auditTypes } from "@/api/user-activity";
import { formatDate } from "@/utils/formatDate";
import { appendLeadingZeroes } from "@/utils/index";
import { debounce } from 'vue-debounce'
import { PropType } from "vue/types/v3-component-props";

const AppProps = Vue.extend({
  props: {
    data: { required: true, type: Object as PropType<PagedResultOfAuditLogDto> }
  },
});

@Component({
  name: "OrderUserActivity",
  components: {
    DialogBox,
    Pagination,
  },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: "danger",
        true: "success",
        null: "success",
      };
      return statusMap[status];
    },
  }
})
export default class extends AppProps {
  filter: string = "";
  auditType: AuditTypes | "" = "";
  sortDirection: "Asc" | "Desc" = "Desc";
  pageNumber = 1;
  pageSize = 10;
  dFn = debounce((val:Function) => val(), '600ms')

  get auditTypesList() {
    return auditTypes;
  }

  async created() {
    // let toQuery = {
    //   filter: this.filter,
    //   pageNumber: this.pageNumber,
    //   pageSize: this.pageSize,
    //   sortDirection: this.sortDirection,
    //   auditType: this.auditType,
    // };
    // this.setQueryWatch(toQuery);
  }

  mounted() {
    setTimeout(() => {
      document.getElementById("search")?.focus();
    }, 0);
  }

  // setQueryWatch(fields: any) {
  //   let queries = this.$route.query;

  //   for (const field in fields) {
  //     if (queries[field]) {
  //       (this as any)[field] = queries[field];
  //     }

  //     this.$watch(field, (val) => {
  //       handleQueries(field, val);
  //     });
  //   }
  // }

  handleJson(value: string | null) {
    if (value) {
      let json;
      let isJson = false;

      try {
        json = JSON.parse(value);
        if (typeof json == "object") {
          isJson = true;
        }
      } catch (error) {
        isJson = false;
      }

      if (isJson && json) {
        let convertedJson = [];
        for (const [key, value] of Object.entries(json)) {
          if (value) {
            convertedJson.push(`<u>${key}</u>: ${value}`);
          }
        }
        return convertedJson.join("<br>");
      }
      return value;
    }
    return null;
  }

  handleDate(date: any) {
    return formatDate(date);
  }

  handleTime(date: any) {
    let hour = new Date(date).getHours();
    let minutes = new Date(date).getMinutes();

    return `${appendLeadingZeroes(hour)}:${appendLeadingZeroes(minutes)}`;
  }

  handleTypeName(type: AuditTypes) {
    const match = auditTypes.find((item) => item.id == type);
    if (match) {
      return match.name;
    }
    return type;
  }

  changeSortDirection() {
    this.sortDirection = this.sortDirection == "Asc" ? "Desc" : "Asc";
    this.pageNumber = 1;
    this.reloadData();
  }

  reloadData() {
    this.$emit('pageChange', this.pageNumber, this.pageSize, this.auditType || undefined, this.filter || undefined, this.sortDirection);
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.reloadData()
  }

  handlePageSizeChange(size: number) {
    this.pageNumber = 1;
    this.pageSize = size;
    this.reloadData()
  }
}
