
import { OrderProductsDto, OrdersViewModel } from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import { PropType } from "vue/types/v3-component-props";
import { formatDate } from "@/utils/formatDate";

@Component({
  name: "TopUps",
  components: { },
  props: {
    data: { required: true, type: Object as PropType<OrdersViewModel> },
  },
})
export default class extends Vue {
  handleDate(date: any) {
    return formatDate(date);
  }

  paymentHistory(product: OrderProductsDto): void {
    console.log('paymentHistory', product.id, product.ordersId);
    //paymentHistory
  }
}
