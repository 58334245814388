
import { OrderMessagesDto, MessageType } from '@/api-client';
import { Component, Vue } from 'vue-property-decorator'
import { PropType } from 'vue/types/v3-component-props';
import { formatDate, formatTime } from "@/utils/formatDate"
import DialogBoxFullscreen from "@/components/DialogBoxFullscreen.vue";
import { ordersNotificationsMessagesIdResendPut } from "@/api/order-messages"
import DialogBox from "@/components/DialogBox.vue";

@Component({
  name: 'Notifications',
  props: {
    data: { required: true, type: Array as PropType<OrderMessagesDto[]> }
  },
  components: { DialogBoxFullscreen, DialogBox }
})
export default class extends Vue {
  messageDialogVisible = false;
  resendDialogVisible = false;
  selectedItem: null | OrderMessagesDto = null;
  typesDdl: {id: MessageType, name: MessageType}[] = [
    {
      id: 'Email',
      name: 'Email'
    },
    {
      id: 'SMS',
      name: 'SMS'
    }
  ]
  typeFilter: "" | MessageType = "";

  get filteredData() {
    return (this.$props.data as OrderMessagesDto[]).filter(item => !item.originalOrderMessageId)
  }

  handleSearch() {
    this.$emit('filter', this.typeFilter)
  }

  handleDate(date: any) {    
    return `${formatDate(date)}\xa0\xa0\xa0\xa0${formatTime(date)}`;
  }

  closeDialog() {
    this.selectedItem = null;
    this.messageDialogVisible = false
    this.resendDialogVisible = false
  }

  viewDialog(row:OrderMessagesDto, type: 'message' | 'resend') {
    this.selectedItem = row;
    if(type == 'message') {
      this.messageDialogVisible = true;
    } else {
      this.resendDialogVisible = true
    }
  }

  resendNotification() {
    if(this.selectedItem) {
      ordersNotificationsMessagesIdResendPut(this.selectedItem.id).then(() => {
        this.$emit('reload')
      })
    }
    this.closeDialog()
  }
}
