import { AuditLogDto, AuditTypes, PagedResultOfAuditLogDto } from "@/api-client";
import { UserActivity } from "@/network/api";
import { handleError } from "@/utils/auth";

export function userActivityGet(
  pgNum: number = 1,
  pgSize: number = 20,
  auditLogTypesId: AuditTypes | undefined = undefined,
  filter: string | undefined = undefined,
  sortDirection: 'Asc' | 'Desc' | undefined = undefined
  ): Promise<PagedResultOfAuditLogDto> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    UserActivity.apiAuditLogsGet(pgNum, pgSize, auditLogTypesId, filter, sortDirection)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function userActivityGetId(id: string): Promise<AuditLogDto> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    UserActivity.apiAuditLogsIdGet(id)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function userActivityOrdersIdGet(
  pgNum: number = 1,
  pgSize: number = 20,
  orderId: string,
  auditLogTypesId: AuditTypes | undefined = undefined,
  filter: string | undefined = undefined,
  sortDirection: 'Asc' | 'Desc' | undefined = undefined
  ): Promise<PagedResultOfAuditLogDto> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    UserActivity.apiOrdersOrdersIdAuditLogsGet(pgNum, pgSize, orderId, auditLogTypesId, filter, sortDirection)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function userActivityCustomerIdGet(
  pgNum: number = 1,
  pgSize: number = 20,
  customerId: string,
  auditLogTypesId: AuditTypes | undefined = undefined,
  filter: string | undefined = undefined,
  sortDirection: 'Asc' | 'Desc' | undefined = undefined
  ): Promise<PagedResultOfAuditLogDto> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    UserActivity.apiCustomersCustomerIdAuditLogsGet(pgNum, pgSize, customerId, auditLogTypesId, filter, sortDirection)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export const auditTypes: Array<{ id: AuditTypes; name: string }> = [
  {
    id: "ApplicationUserRoles",
    name: "Application User Role",
  },
  {
    id: "BillingCycle",
    name: "Billing Cycle",
  },
  {
    id: "Customers",
    name: "Customers",
  },
  {
    id: "Products",
    name: "Products",
  },
  {
    id: "OrderCost",
    name: "Order Cost",
  },
  {
    id: "OrderHistory",
    name: "Order History",
  },
  {
    id: "OrderInvoices",
    name: "Order Invoices",
  },
  {
    id: "OrderPayment",
    name: "Order Payment",
  },
  {
    id: "OrderProducts",
    name: "Order Products",
  },
  {
    id: "Orders",
    name: "Orders",
  },
  {
    id: "Users",
    name: "Users",
  }
]