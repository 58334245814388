
import { IDType, OrderSubStatesEnum, OrdersViewModel, OrderStateHistoryViewModel } from '@/api-client';
import { Component, Vue, Watch } from 'vue-property-decorator'
import { PropType } from 'vue/types/v3-component-props';
import { formatDate, formatTime } from "@/utils/formatDate"
import { formatName, orderSubStatesFilter} from '@/utils';
import CustomerEditForm from '@/components/Forms/CustomerEditForm.vue';
import { hasClaim } from '@/utils/claims';
import { orderHistoryGet } from "@/api/orders";

type idTypeDisplay = Record<IDType, string>

@Component({
  name: 'Account',
  props: {
    data: { required: true, type: Object as PropType<OrdersViewModel> },
    customerOnly: { type: Boolean, default: false}
  },
  components: {
    CustomerEditForm
  },
  filters: {
    statusFilter: (status:OrderSubStatesEnum) => orderSubStatesFilter(status)
  }
})
export default class extends Vue {

  idTypeDisplay: idTypeDisplay = {
    IDNumber: "ID Number",
    Passport: "Passport"
  };
  col = 2;
  tableKey = 1;
  isEditingCustomer = false
  userHasPermisson = false
  orderHistory: OrderStateHistoryViewModel[] = []

  async created() {
    this.userHasPermisson = await hasClaim("ManageCustomer");
    window.addEventListener("resize", this.setRowCols);
    this.setRowCols();
  }
  
  @Watch('$props.data', { immediate: true })
  private async productLoad() {
    if (this.$props.data.id) {
      this.orderHistory = await orderHistoryGet(this.$props.data.id);
    }
  }
  
  handleDate(date:any) {
    return formatDate(date)
  }
  
  handleTime(time:any) {
    return formatTime(time)
  }

  handleName(name:string) {
    return formatName(name)
  }

  setRowCols() {
    const mediaQuery = window.matchMedia("(min-width: 993px)");
    const colToDisplay = mediaQuery.matches ? 2 : 1;
    if(this.col != colToDisplay) {
      this.col = colToDisplay
      this.tableKey += 1;
    }
  }

  destroyed() {
    window.removeEventListener("resize", this.setRowCols);
  }
}
