import { ApiCustomersAdminIdGetRequest, SendUpdateOtpDto, CustomersDto, CustomersUpdateEmailDto, CustomersUpdateMobileNumberDto, ApiCustomersIdMobilenumberPutRequest, ApiCustomersIdEmailPutRequest, MessageType, PagedResultOfCustomerOrdersDto, PagedResultOfCustomersDto, CustomerPaymentMethodDto, ApiCustomerPaymentMethodGetRequest } from "@/api-client";
import { Customers, CustomerOtps, CustomerPaymentMethods } from "@/network/api";
import { handleError } from "@/utils/auth";
import { Message } from "element-ui";

export function customersGet(
  pgNum: number = 1,
  pgSize: number = 20,
  filter: string | undefined = undefined,
  ): Promise<PagedResultOfCustomersDto> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    Customers.apiCustomersAdminGet(pgNum, pgSize, filter)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function customersGetId(id: string): Promise<CustomersDto> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    Customers.apiCustomersAdminIdGet(id)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function customersIdOrdersGet(
  id: string,
  pgNum: number = 1,
  pgSize: number = 20,
  ): Promise<PagedResultOfCustomerOrdersDto> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    Customers.apiCustomersIdOrdersGet(pgNum, pgSize, id)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function customersAdminIdPut(id: string, item: ApiCustomersAdminIdGetRequest): Promise<void> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    Customers.apiCustomersAdminIdPut(id, item)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        Message.success({ message: "Updated!", showClose: true })
        resolve()
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function sendCustomerOtp(item: SendUpdateOtpDto): Promise<void> {
  return new Promise((resolve, reject) => {
    CustomerOtps.apiCustomersIdSendOtpPost(item.customersId, {id: item.customersId, dto: item})
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          Message.success({ message: "OTP sent!", showClose: true, });
          resolve(res.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function updateCustomerContact(type: MessageType, item: CustomersUpdateMobileNumberDto | CustomersUpdateEmailDto, isGeneralForm: boolean = false): Promise<void> {
  return new Promise((resolve, reject) => {
    if (type == "SMS") {
      Customers.apiCustomersIdMobilenumberPut(item.id, {id: item.id, dto: item} as ApiCustomersIdMobilenumberPutRequest)
        .then((res) => {
          if ([200, 201, 204].includes(res.status)) {
            if(!isGeneralForm) {
              Message.success({ message: "Cellphone number updated!", showClose: true });
            }
            resolve(res.data);
          }
        })
        .catch((error) => {
          handleError(error);
          reject(error);
        });
    } else if (type == "Email") {
      Customers.apiCustomersIdEmailPut(item.id, {id: item.id, dto: item} as ApiCustomersIdEmailPutRequest)
        .then((res) => {
          if ([200, 201, 204].includes(res.status)) {
            if(!isGeneralForm) {
              Message.success({ message: "Email address updated!", showClose: true });
            }
            resolve(res.data);
          }
        })
        .catch((error) => {
          handleError(error);
          reject(error);
        });
    }
  });
}

export function customersPaymentMethodsGet(customerId: string): Promise<CustomerPaymentMethodDto> {
  return new Promise((resolve, reject) => {
    CustomerPaymentMethods.apiCustomerPaymentMethodIdGet(customerId)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function customersPaymentMethodPost(paymentMethod: ApiCustomerPaymentMethodGetRequest | undefined): Promise<void> {
  return new Promise((resolve, reject) => {
    CustomerPaymentMethods.apiCustomerPaymentMethodPost(paymentMethod)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          Message.success({ message: "A payment link has been sent to the customer to register their new payment method!", showClose: true, });
          resolve();
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}