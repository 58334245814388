import { ApiOrdersPaymentRequestPostRequest } from "@/api-client";
import { OrderPaymentRequests } from "@/network/api";
import { handleError } from "@/utils/auth";
import { Message } from "element-ui";

export function ordersPaymentRequestPost(item: ApiOrdersPaymentRequestPostRequest): Promise<void> {
  return new Promise((resolve, reject) => {
    OrderPaymentRequests.apiOrdersPaymentRequestPost(item)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        Message.success({ message: "Payment link resent!", showClose: true })
        resolve()
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}