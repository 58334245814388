
import { OrderSubStatesEnum, PagedResultOfCustomerOrdersDto } from '@/api-client';
import { Component, Vue } from 'vue-property-decorator'
import { PropType } from 'vue/types/v3-component-props';
import { formatDate } from "@/utils/formatDate"
import { formatName, orderSubStatesFilter } from '@/utils';
import Pagination from "@/components/Pagination.vue";

@Component({
  name: 'AllOrders',
  props: {
    orderId: { type: String },
    from: { type: String as PropType<"customer" | "order" | "paymentMethodCRUD">, default: "order" },
    data: { required: true, type: Object as PropType<PagedResultOfCustomerOrdersDto> }
  },
  filters: { 
    statusFilter: (status:OrderSubStatesEnum) => orderSubStatesFilter(status)
  },
  components: { Pagination }
})
export default class extends Vue {
  pageNumber = this.$props.data.pageNumber || 1;
  pageSize = this.$props.data.pageSize || 20;
  selection: Array<any> = [];

  handleDate(date: any) {    
    return formatDate(date);
  }

  handleName(name:string) {
    return formatName(name)
  }

  handlePageNumberClick(page: any) {
    this.selection = [];
    this.pageNumber = page;
    this.$emit('pageChange', this.pageNumber, this.pageSize);
  }

  handlePageSizeChange(size: number) {
    this.selection = [];
    this.pageNumber = 1;
    this.pageSize = size;
    this.$emit('pageChange', this.pageNumber, this.pageSize);
  }

  handleSelect(id: any) {
    if (id && !this.selection.includes(id)) {
      this.selection = [id];
    } else {
      this.selection = [];
    }
    this.$emit('orderSelect', this.selection)
  }
}
